// var d3 = require("d3");
var Promise = require('promise');

var barChart = function(svg, maxWidth, barHeight, barSpacing) {
	this.element    = svg;
	this.svg        = d3.select(svg);
	this.innerSVG   = this.svg.append("g");
	this.maxWidth   = maxWidth;
	this.innerWidth = maxWidth - this.margins.left - this.margins.right;
	this.barHeight  = barHeight;
	this.barSpacing = barSpacing;
	this.maxWidth   = maxWidth;

	this.innerSVG
		.attr("class", "chart-inner")
		.attr("transform", "translate(" + this.margins.left + "," + this.margins.top + ")");

	// this.setSVGWidth();
	this.svg.attr("xmlns", "http://www.w3.org/2000/svg")
	this.svg.attr("preserveAspectRatio", "xMinYMin meet")
}

barChart.prototype.margins = {
	top: 20, 
	right: 30, 
	bottom: 30, 
	left: 140
}

barChart.prototype.scales = {}

barChart.prototype.setSVGWidth = function() {
	this.svg.style("width", this.maxWidth);
}

barChart.prototype.setSVGViewBox = function() {
	this.svg.attr("viewBox", "0 0 " + this.maxWidth + " " + this.calculateTotalHeight());
}

barChart.prototype.setSVGHeight = function() {
	this.svg.style("height", this.calculateTotalHeight());
}

barChart.prototype.calculateTotalHeight = function() {
	return this.calculateInnerHeight() + this.margins.top + this.margins.bottom;
}

barChart.prototype.calculateInnerHeight = function() {
	return this.barHeight * this.data.length;
}

barChart.prototype.buildXScale = function() {
	this.scales.x = d3.scaleLinear().range([0, this.innerWidth]);
}

barChart.prototype.buildYScale = function() {
	this.scales.y = d3.scaleBand().rangeRound([0, this.calculateInnerHeight()]);
}

barChart.prototype.setXScaleDomain = function(data) {
	this.scales.x.domain([0, d3.max(data, this.getValue)]);
}

barChart.prototype.setYScaleDomain = function(data) {
	this.scales.y.domain(data.map(this.getName));
}

barChart.prototype.getBarFillColor = function(data) {
	var cssClass = "bar";
   
    if (data.value == 0) {
		cssClass = cssClass + " risk-zero";
	} else if (data.value <= 3) {
		cssClass = cssClass + " risk-low";
    } else if (data.value <= 6) {
		cssClass = cssClass + " risk-moderate";
    } else if (data.value <= 10) {
	    cssClass = cssClass + " risk-high";
	}

    return cssClass;
}

barChart.prototype.getValue = function(data) {
	return data.value;
}

barChart.prototype.getDisplayValue = function(data) {
	return data.display_value;
}

barChart.prototype.loadData = function(url) {
	var promise = this.getPromise();
	d3.json(url, this.buildChart.bind(this));
	return promise;
}

barChart.prototype.getPromise = function() {
    return new Promise(function(resolve, reject) {
    	this.resolve = resolve;
    	this.reject = reject;
    }.bind(this));
}

barChart.prototype.createXAxis = function() {
	var tickSize = -(this.calculateTotalHeight() - this.margins.top - this.margins.bottom);
	var height   = this.calculateTotalHeight() - this.margins.bottom;
	var axis     = d3.axisBottom(this.scales.x).tickSizeInner(tickSize);

	this.svg.append("g")
		.attr("class", "x-axis axis")
		.attr("transform", "translate("+ this.margins.left + "," + height + ")")
		.lower()
		.call(axis)
}

barChart.prototype.createYAxis = function() {
	this.svg.append("g")
		.attr("class", "y-axis axis")
		.attr("transform", "translate("+ this.margins.left + "," + this.margins.top + ")")
		.lower() 
		.call((d3.axisLeft(this.scales.y)));
}

barChart.prototype.buildChart = function(error, data) {
	this.data = data;
	
	this.setSVGViewBox();
	// this.setSVGHeight();
	this.buildYScale();
	
	this.setXScaleDomain(data);
	this.setYScaleDomain(data);
	
	this.createXAxis();
	this.createYAxis();

	this.buildBars();

	this.resolve(this.element);
}

barChart.prototype.setBarSpacing = function(data, index) {
	return "translate(0," + index * this.barHeight + ")";	
}

barChart.prototype.getName = function(data) {
	return data.name;
}

barChart.prototype.buildBars = function() {
	var bars      = this.innerSVG.selectAll("g").data(this.data);
	var barsEnter = bars.enter();
	var groups    = barsEnter.append("g")
					.attr("transform", this.setBarSpacing.bind(this))
					.attr("class", this.getBarFillColor);

	this.setBarInner(groups);
	this.setBarText(groups);
}

barChart.prototype.setBarInner = function(groups) {
	groups.append("rect")
		.attr("width", this.getSingleBarWidth.bind(this))
		.attr("height", this.getSingleBarHeight.bind(this))
}

barChart.prototype.getSingleBarWidth = function(data) {
	return this.scales.x(data.value);
}

barChart.prototype.setBarText = function(groups) {
	groups.append("text")
	    .attr("x", this.getTextOffsetX.bind(this))
	    .attr("y", this.getTextOffsetY.bind(this))
	    .attr("dy", ".35em")
	    .text(this.getDisplayValue);
}

barChart.prototype.getSingleBarHeight = function(data) {
	return this.barHeight - this.barSpacing;
}

barChart.prototype.getTextOffsetX = function(data) {
	return this.scales.x(data.value) - 10;
}

barChart.prototype.getTextOffsetY = function(data) {
	return (this.barHeight / 2)
}

module.exports = barChart;