var slider = require("./slider");

var FieldConditional = function($conditional) {
	this.$conditional = $conditional;
	this.$group = $conditional.closest(".slider-group");
}

FieldConditional.prototype.buildDependency = function() {
	var id = this.$conditional.data("condition-depends-on");
	this.dependency = {
		"id": id,
		"selector": '[data-field-id="' + id + '"]',
		"$field": $('[data-field-id="' + id + '"]'),
		"neededValue": this.$conditional.data("condition-value"),
	}
}

FieldConditional.prototype.attachListener = function() {
	this.dependency.$field.on("change", $.proxy(this.handleChange, this));
}

FieldConditional.prototype.handleChange = function(event) {
	var $target     =  $(event.target) || this.dependency.$field
	var actualValue = this.getActualValue($target);

	if (this.isDependencyMet(actualValue) ) {
		this.open(event);
	} else {
		this.close(event);
	}
}

FieldConditional.prototype.isDependencyMet = function(value) {
	var isMet = false;
	var actualValue = value || this.getActualValue(this.dependency.$field);

	if (typeof actualValue !== "undefined")  {
		if (!$.isArray(actualValue) && !$.isArray(this.dependency.neededValue)) {
			actualValue = JSON.parse(actualValue);
		}

		isMet = this.evaluateCondition(actualValue)
	}
	return isMet;
}

FieldConditional.prototype.getActualValue = function($target) {
	var actualValue = $target.val();
	var inputType = $target.attr("type");
	
	if (inputType === "radio" || inputType === "checkbox") {
		actualValue = $(this.dependency.selector + ":checked").val();
	}

	return actualValue;
}

FieldConditional.prototype.evaluateCondition = function(actualValue) {
	var condition  = actualValue === this.dependency.neededValue;

	if (typeof this.dependency.neededValue == 'object' ) {
		condition = this.dependency.neededValue.indexOf(actualValue) > -1;
	}

	if ($.isArray(actualValue)) {
		condition = $.inArray(this.dependency.neededValue, actualValue) > -1;
	}

	return condition;
}

FieldConditional.prototype.open = function(event) {
	slider.call(this.$group, event, "addClass");
}

FieldConditional.prototype.close = function(event) {
	slider.call(this.$group, event, "removeClass");
}

FieldConditional.prototype.triggerChange = function() {
	this.dependency.$field.trigger("change");
}

module.exports = FieldConditional;