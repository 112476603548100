var svg2png  = require("./svg2png");
var Promise = require('promise');

module.exports = function(svg) {
	var postChart = function(data) {
		
		var request = new XMLHttpRequest();
		request.open('POST', '/api/chart.png', true);
		request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

		var resolvePost = function(resolve, reject) {
			request.onreadystatechange = function() {
				if (request.readyState == 4 && request.status == 200) {
					resolve();	
				}
			}
	    }

	    var postPromise = new Promise(resolvePost);
		request.send("chart=" + encodeURIComponent(data.svgURI));
		return postPromise;
	}
	
	// var pngPromise = new svg2png(svg);
	// return pngPromise.then(postChart);

	var svgURI = new svg2png(svg);
	return postChart(svgURI);
}