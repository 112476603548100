/* ======================================================================
	App Global
	===================================================================== */

	window.App = window.App || {};

/* ==========================================================================
	Slider
	========================================================================= */

	var slider = require("./slider");
	
	$(".slider-trigger").on("click", slider);
	$(".slider-close").on("click", slider);
	$(".slider-open").on("click", slider);

	$(".slider-condition").on("change", function(event) {
		slider(event);
	});

/* ======================================================================
	Toggle Slider Nav Active State
	===================================================================== */

	$(".slider-nav .nav-link").on("click", function(event) {
		event.preventDefault();
		$(this).closest(".slider-nav").find(".active").removeClass("active");
		$(this).closest(".nav-item").addClass("active");
	});

	$(".nav-item .nav-link").on("click", function(event) {
		$('.feedback-form').removeClass('complete');
		$('.feedback-form').find('.message').empty();
	});

/* ======================================================================
	Report Nav // Toggle Download Link
	===================================================================== */

	$(".report-toggle-nav .nav-link").on("click", function() {
		var value = $(this).text().toLowerCase();
		$(".download-form").find('[name="pdf[type]"]').val(value);
	})

/* ==========================================================================
	Conditional Forms
	========================================================================= */

	var conditionalFieldFactory = require("./FieldConditionalFactory");
	conditionalFieldFactory.buildFieldConditions();

/* ==========================================================================
	Calculation Fields
	========================================================================= */
	
	var getAge = function(dateString) {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		    age--;
		}
		return age;
	}

	var check_calculations = function($input) {
		var date = $input.val();

		return getAge(date);
	}

	var find_fields_to_watch = function($points) {
		var toWatch = [];
		$.each($points, function(index, selector) {
			if ($(selector).length) {
				toWatch.push(selector);
			}
		});
		return toWatch;
	}

	var init_calculation_fields = function() {
		$('[data-calculation]').each(function() {
			var $self = $(this);
			var $points = $(this).data("calculation-points");
			var $toWatch = find_fields_to_watch($points);

			$.each($toWatch, function(index, selector) {
				$(selector).on("change", function() {
					var age = check_calculations($(this));
					$self.val(age);
				});
			});
		});
	}

	init_calculation_fields();

/* ==========================================================================
	Highlighting Missing Fields
	========================================================================= */

	var getValueFromName = function(name) {
		var selector = '[name="' + name + '"]';
		var $input = $(selector);

		if ($input.attr("type") === "radio") {
			$input = $(selector + ":checked");
		}

		return $input.val();
	}

	var handle_row_skip = function(row) {
		var $row   = $(row);
		var $input = $row.find(":input");

		add_remove_row_class($row, $input);
		add_change_event($row, $input);
	}

	var add_remove_row_class = function($row, $input) {
		if (getValueFromName($input.attr("name"))) {
			$row.removeClass("skipped");
		} else {
			$row.addClass("skipped");
		}
	}

	var add_change_event = function($row, $input) {
		$input.on("change", function(event) {
			$row.removeClass("skipped");
		})
	}

	var attach_events = function() {
		var $rows = $(".carat-form .form-row:not(.form-footer):not(.message-row)")

		$.each($rows, function (index, row) {
			var $row   = $(row);
			var $input = $row.find(":input");

			$input.on("focus", function() {
				for (var i = index - 1; i >= 0; i--) {
					handle_row_skip($rows[i]);
				}
			});
		});
	}

	attach_events();

/* ======================================================================
	Inline SVG Styles
	===================================================================== */

	window.computedToInline = require("computed-style-to-inline-style");

	window.inlineStyler = function() {
		var chart = document.querySelector(".chart");
		if (chart) {
			window.computedToInline(chart, true);
		}
	}

/* ======================================================================
	Download Form
	===================================================================== */

	var saveChart = require("./chart/save");
	var buildChart = require("./chart/build");
	var buildChartOnLoad = require("./chart/buildOnLoad");
	buildChartOnLoad();	

	$(function() {
		$(".download-form").on("submit", build_chart_for_pdf);
	});

	var add_loader = function($form) {
		var $submit = $form.find('input[type="submit"]');
		$submit.addClass("disabled");
		$submit.after('<div class="loader">Loading...</div>');
	}

	var remove_loader = function($form) {
		$form.find('input[type="submit"]').removeClass("disabled");
		$form.find(".loader").remove();			
	}	

	var build_chart_for_pdf = function(event) {
		event.preventDefault();
		
		var $form = $(this);
		var chart = buildChart();
		
		add_loader($form);

		chart.then(saveChart).then(function() {
			remove_loader($form);
			$form.off("submit", build_chart_for_pdf);
			$form.trigger("submit");
			$form.on("submit", build_chart_for_pdf);
		});

		return false;
	}

/* ======================================================================
	Create New Assessment
	===================================================================== */

	$("#new-assessment-btn").on("click", function(event) {
		if (window.confirm("Warning! Starting a new assessment will erase any data entered for this patient. To save the current data you've entered, select 'Cancel' in this message and then select 'Save Data' below.")) {
			return true;
		}
		event.preventDefault();
	});

	$("#upload-btn").on("click", function(event) {
		if (window.confirm("Warning! Uploading a new assessment will erase any data entered for the current patient. To save the current data you've entered, select 'Cancel' in this message and then select 'Save Data' below.")) {
			return true;
		}
		event.preventDefault();
	});	

/* ======================================================================
	Dates
	===================================================================== */

	var future_date_checker = function(event) {
		var now  = new Date();
		var date = new Date($(this).val());

		if (date > now) {
			insert_date_error_message($(this), "Date selected is not allowed. Please enter new date.")
		}
	}

	var invalid_date_checker = function(event) {
		var value = $(this).val();

		if (value.split("/")[0] > 12) {
			insert_date_error_message($(this), "Invalid date format. Please enter new date.");
		}
	}	

	var insert_date_error_message = function($input, message) {
		var $error = $('<span class="date-error"></span>');
		$error.text(message);
		$error.insertAfter($input);

		$input.datepicker("clearDates");

		$input.one("changeDate", function (event) {
			$error.remove();
		})
	}

	var clear_date_error_message = function(event) {
		console.log( "in event" )
		$(event.target).next(".date-error").remove()
	}	

	$('.date-input').on("change", future_date_checker);
	$('.date-input').on("changeDate", invalid_date_checker);	


/* ======================================================================
	Feedback Form
	===================================================================== */

	$(".feedback-form").on("submit", function(event) {
		event.preventDefault();
		var $form = $(this);

		var $ajax = $('<input type="hidden" name="' + App.config.FEEDBACK_KEY + '[ajax]" value="true" />');
		$(this).append($ajax);

		var $submit = $form.find('input[type="submit"]');
		$submit.addClass("disabled");
		$submit.after('<div class="loader">Loading...</div>');

        $.ajax({
            url     : $(this).attr('action'),
            type    : $(this).attr('method'),
            dataType: 'json',
            data    : $(this).serialize(),
	        error: function(response, error) {
	        	var $message = $('<p class="error-message">An error occurred. ' + error + '</p>');
			$form.addClass("error");
			$form.find(".message").html($message);
		    	$submit.removeClass("disabled");
		    	$form.find(".loader").remove();					
			},			
        }).done(function(response) {
        	var $message;
	    	if (response.valid) {
	    		$message = $('<p class="confirmation-message">Thank you! Your feedback has been sent.</p>');
	    		$form.addClass("complete");
	    	} else {
	    		$message = $('<p class="error-message">An error occurred. ' + response.error_text + '</p>');
	    		$form.addClass("error");
	    	}

	    	$submit.removeClass("disabled");
	    	$form.find(".loader").remove();
    		$form.find(".message").html($message);
	});

        return false;
	});

/* ==========================================================================
	Mobile Tester
	========================================================================= */

	// var isSmall = function() {
	// 	return $(window).width() < 1000;
	// };

/* ==========================================================================
	Mobile
	========================================================================= */

	// $(".collapsing-nav-trigger").on("click", function(event) {
	// 	event.preventDefault();
	// 	$(this).closest(".collapsing-nav").toggleClass("open");
	// });