var FieldConditional = require("./FieldConditional.js");

module.exports = {
	buildFieldConditions: function() {
		$("[data-condition-depends-on]").each(this.buildFieldCondition);
	},

	buildFieldCondition: function() {
		var conditional = new FieldConditional($(this));
		var currentValue;

		conditional.buildDependency();
		conditional.attachListener();

		if (conditional.isDependencyMet(conditional.getActualValue(conditional.dependency.$field))) {
			conditional.open();
		}
	}
}
