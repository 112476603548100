var barChart = require("./barChart");

module.exports = function() {
	var svg = document.querySelector(".chart");

	if (!svg) {
		svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		svg.setAttribute("class", "chart");
		svg.id = "chart";
	}

	var chart = new barChart(svg, 700, 50, 10);
	chart.buildXScale();
	return chart.loadData("/api/report.json");
}