module.exports = function(event, action) {

	var master = this;
	var runner = {};
	var $cntnr, $collection, $open;

/* ==========================================================================
	Get Some Vars
	========================================================================= */

	// .is() over .hasClass() for SVG support :(
	action = action || false;
	action = $(this).is(".slider-trigger") ? "toggleClass" : action;
	action = !action && $(this).is(".slider-close") ? "removeClass" : action;
	action = !action && $(this).is(".slider-open") ? "addClass" : action;

	$cntnr = $(this).data("slider-group") ? $( $(this).data("slider-group") ) : $(this).closest(".slider-group");
	$collection = $cntnr.data("slider-collection") ? $( $cntnr.data("slider-collection") ) : false;

/* ==========================================================================
	Transition Detection
	========================================================================= */

	function whichTransitionEvent(){
		var t,
			el = document.createElement("fakeelement");

		var transitions = {
			"transition"      : "transitionend",
			"OTransition"     : "oTransitionEnd",
			"MozTransition"   : "transitionend",
			"WebkitTransition": "webkitTransitionEnd"
		}

		for (t in transitions){
			if (el.style[t] !== undefined){
				return transitions[t];
			}
		}
	}

	var transitionEvent = whichTransitionEvent();

/* ==========================================================================
	Runner
	========================================================================= */

	runner.run = function($cntnr, action) {

		var self = this;
	
/* ==========================================================================
	Var Setup
	========================================================================= */

		this.action  = action;
		this.$cntnr  = $cntnr;
		this.type    = this.$cntnr.data("slide");
		this.$target = this.$cntnr.find(".slider-target");

/* ==========================================================================
	Make a Clone for Size Comparisons
	========================================================================= */

		this.makeClone = function() {
			self.$clone = self.$cntnr.clone();

			var $checked = self.$target.find(":checked");
			$checked.prop("checked", false);
			
			self.$clone.css({
				display:    'block',
				position:   'fixed',
				visibility: 'hidden',
			});

			self.$clone.appendTo(self.$cntnr.parent());
			self.$cloneTarget = self.$clone.find(".slider-target");
			$checked.prop("checked", true);
		};

/* ==========================================================================
	Animation Types
	========================================================================= */

		this.vertical = function() {
			// First explicitly set our current height
			self.$target.height(self.$cloneTarget.outerHeight());
			
			// Then toggle the class, and set our new height
			self.$clone[self.action]("open");
			var height = self.$cloneTarget.outerHeight();
			self.$target.height(height);
		};

		this.horizontal = function() {
			// First explicitly set our current width
			self.$target.width(self.$cloneTarget.outerWidth());
			
			// Then toggle the class, and set our new width
			self.$clone[self.action]("open");
			var width = self.$cloneTarget.outerWidth();
			self.$target.width(width);
		};

/* ==========================================================================
	Callable Funcs
	========================================================================= */

		this.toggle = function() {
			self.$cntnr.toggleClass("open");
		};

		this.open = function() {
			self.$cntnr.remove("open");
		};

		this.close = function() {
			self.$cntnr.remove("open");
		};

/* ==========================================================================
	Utility
	========================================================================= */

		this.cleanup = function() {
			self.$clone.remove();
		};

/* ==========================================================================
	Do the thing.
	========================================================================= */

		if (
			(!self.$cntnr.hasClass("open") && self.action === "addClass")
			|| (self.$cntnr.hasClass("open") && self.action === "removeClass")
			|| (self.action === "toggleClass")
		) {
			if (event) {
				event.preventDefault();
			}

			self.$target.one(transitionEvent, function(event) {
				$(this).removeClass("animating").removeAttr("style");
			});

			this.makeClone();
			this.$target.addClass("animating")
			this[this.type].call();
			this.$cntnr.trigger(self.action, [this.$target]);

			this.$cntnr[self.action]("open");
			this.cleanup();
		}

	};

/* ==========================================================================
	Do the Things
	========================================================================= */

	if ($collection) {
		$open = $collection.find(".open");
		($open.length && !$open.is($cntnr)) && runner.run($open, "removeClass");
	}

	$cntnr.each(function() {
		runner.run($(this), action);
	});
};
