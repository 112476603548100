var svgInlineStyler = function(svg, clone) {
	this.container = document.createElement("div");
	this.container.style["overflow"] = "hidden";
	this.container.style["height"] = "0px";

	if (typeof svg === "string") {
		svg = document.querySelector(svg);
	}

	if (!document.body.contains(svg)) {
		clone = true;
	}

	if (clone) {
		svg = svg.cloneNode(true);
		this.container.appendChild(svg);
	}

	this.svg = svg;
}

svgInlineStyler.prototype.prefix = {
	xmlns: "http://www.w3.org/2000/xmlns/",
	xlink: "http://www.w3.org/1999/xlink",
	svg: "http://www.w3.org/2000/svg"
};

svgInlineStyler.prototype.getSVG = function() {
	return this.svg;
}


svgInlineStyler.prototype.inlineStyles = function() {
	this.defaultSvgStyle = this.getDefaultSvgStyle();
	this.resetSvgAttrs();

	return this.setInlineStyles(this.svg);
}

svgInlineStyler.prototype.getDefaultSvgStyle = function() {
	var emptySvg = window.document.createElementNS(this.prefix.svg, 'svg');
	this.container.appendChild(emptySvg);
	document.body.appendChild(this.container);
	return getComputedStyle(emptySvg);
}

svgInlineStyler.prototype.resetSvgAttrs = function() {
	this.svg.setAttribute("version", "1.1");

	this.svg.removeAttribute("xmlns");
	this.svg.removeAttribute("xlink");

	if (!this.svg.hasAttributeNS(this.prefix.xmlns, "xmlns")) {
		this.svg.setAttributeNS(this.prefix.xmlns, "xmlns", this.prefix.svg);
	}

	if (!this.svg.hasAttributeNS(this.prefix.xmlns, "xmlns:xlink")) {
		this.svg.setAttributeNS(this.prefix.xmlns, "xmlns:xlink", this.prefix.xlink);
	}
}

svgInlineStyler.prototype.setInlineStyles = function(svg) {
	var allElements = this.traverse(svg);
	var index = allElements.length;
	
	while (index--) {
		this.setInlineStyle(allElements[index]);
	}
	return svg;
}

svgInlineStyler.prototype.traverse = function(obj) {
	var tree = [];
	tree.push(obj);
	visit(obj);
	
	function visit(node) {
		if (node && node.hasChildNodes()) {
			var child = node.firstChild;
			while (child) {
				if (child.nodeType === 1 && child.nodeName != 'SCRIPT'){
					tree.push(child);
					visit(child);
				}
				child = child.nextSibling;
			}
		}
	}
	
	return tree;
}

svgInlineStyler.prototype.setInlineStyle = function(element) {
	var clientRect = { width: "auto", height: "auto"};
	var cSSStyleDeclarationComputed = getComputedStyle(element);
	var i, len, key, value;
	var computedStyleStr = "";
	
	if (element.tagName === "rect") {
		clientRect = element.getBoundingClientRect();
	}
	
	for (i = 0, len = cSSStyleDeclarationComputed.length; i < len; i++) {
		key = cSSStyleDeclarationComputed[i];
		value = cSSStyleDeclarationComputed.getPropertyValue(key);
		
		if (value !== this.defaultSvgStyle.getPropertyValue(key)) {
			computedStyleStr += key + ":" + value + ";";
		}
	}
	
	element.setAttribute('style', computedStyleStr);
	
	if (element.tagName === "rect") {
		element.style["width"] = element.width.baseVal.valueAsString;;
		element.style["height"] = element.height.baseVal.valueAsString;;
	}     
}

module.exports = svgInlineStyler;
