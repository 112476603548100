var svgInlineStyler = require("./compute-inline-styles");
var Promise = require('promise');

var svg2png = function(svg) {
	var styler;

	if (typeof svg === "string") {
		svg = document.querySelector( "svg" );
	}

	styler = new svgInlineStyler(svg, true);
	this.svg = styler.inlineStyles();
	
	this.svgData = new XMLSerializer().serializeToString(this.svg);
	this.svgURI  = "data:image/svg+xml;base64," + btoa(this.svgData);

	return this.svgURI;

	// this.buildCanvas();
	// this.buildImage();
	// return this.pngPromise();
}

// svg2png.prototype.buildCanvas = function() {
// 	var svgSize = this.svg.getBoundingClientRect();

// 	this.canvas = document.createElement("canvas");

//     this.canvas.width = svgSize.width;
//     this.canvas.height = svgSize.height;	

//     this.context = this.canvas.getContext("2d");
// }

// svg2png.prototype.buildImage = function() {
// 	this.img = document.createElement("img");
// }

// svg2png.prototype.getDataURI = function() {
//     try {
// 	    this.context.drawImage(this.img, 0, 0);
//     }
//     catch (err) {
//         setTimeout(this.context.drawImage(this.img, 0, 0),1);
//     }
	
// 	this.context.globalCompositeOperation = "destination-over";
// 	this.context.fillStyle = "#ffffff";
// 	this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

// 	window.cvs = this.canvas;

//     return this.canvas.toDataURL("image/png", 1.0);
// };

// svg2png.prototype.pngPromise = function() {
//     return new Promise(this.promiseResolution.bind(this));
// }

// svg2png.prototype.promiseResolution = function(resolve, reject) {
// 	var loadFunc = function() {
// 		window.img = this.img;
//         resolve(this.getDataURI());
//     };

//     var errorFunc = function(){
//         reject();
//     };

//     // this.img.onload = loadFunc.bind(this);
//     this.img.onload = $.proxy(loadFunc, this);
//     this.img.onerror = $.proxy(errorFunc, this);

// 	this.img.setAttribute("src", this.svgURI);
// }

module.exports = svg2png;