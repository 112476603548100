(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var slider = require("./slider");

var FieldConditional = function FieldConditional($conditional) {
	this.$conditional = $conditional;
	this.$group = $conditional.closest(".slider-group");
};

FieldConditional.prototype.buildDependency = function () {
	var id = this.$conditional.data("condition-depends-on");
	this.dependency = {
		"id": id,
		"selector": '[data-field-id="' + id + '"]',
		"$field": $('[data-field-id="' + id + '"]'),
		"neededValue": this.$conditional.data("condition-value")
	};
};

FieldConditional.prototype.attachListener = function () {
	this.dependency.$field.on("change", $.proxy(this.handleChange, this));
};

FieldConditional.prototype.handleChange = function (event) {
	var $target = $(event.target) || this.dependency.$field;
	var actualValue = this.getActualValue($target);

	if (this.isDependencyMet(actualValue)) {
		this.open(event);
	} else {
		this.close(event);
	}
};

FieldConditional.prototype.isDependencyMet = function (value) {
	var isMet = false;
	var actualValue = value || this.getActualValue(this.dependency.$field);

	if (typeof actualValue !== "undefined") {
		if (!$.isArray(actualValue) && !$.isArray(this.dependency.neededValue)) {
			actualValue = JSON.parse(actualValue);
		}

		isMet = this.evaluateCondition(actualValue);
	}
	return isMet;
};

FieldConditional.prototype.getActualValue = function ($target) {
	var actualValue = $target.val();
	var inputType = $target.attr("type");

	if (inputType === "radio" || inputType === "checkbox") {
		actualValue = $(this.dependency.selector + ":checked").val();
	}

	return actualValue;
};

FieldConditional.prototype.evaluateCondition = function (actualValue) {
	var condition = actualValue === this.dependency.neededValue;

	if (_typeof(this.dependency.neededValue) == 'object') {
		condition = this.dependency.neededValue.indexOf(actualValue) > -1;
	}

	if ($.isArray(actualValue)) {
		condition = $.inArray(this.dependency.neededValue, actualValue) > -1;
	}

	return condition;
};

FieldConditional.prototype.open = function (event) {
	slider.call(this.$group, event, "addClass");
};

FieldConditional.prototype.close = function (event) {
	slider.call(this.$group, event, "removeClass");
};

FieldConditional.prototype.triggerChange = function () {
	this.dependency.$field.trigger("change");
};

module.exports = FieldConditional;

},{"./slider":10}],2:[function(require,module,exports){
"use strict";

var FieldConditional = require("./FieldConditional.js");

module.exports = {
	buildFieldConditions: function buildFieldConditions() {
		$("[data-condition-depends-on]").each(this.buildFieldCondition);
	},

	buildFieldCondition: function buildFieldCondition() {
		var conditional = new FieldConditional($(this));
		var currentValue;

		conditional.buildDependency();
		conditional.attachListener();

		if (conditional.isDependencyMet(conditional.getActualValue(conditional.dependency.$field))) {
			conditional.open();
		}
	}
};

},{"./FieldConditional.js":1}],3:[function(require,module,exports){
"use strict";

// var d3 = require("d3");
var Promise = require('promise');

var barChart = function barChart(svg, maxWidth, barHeight, barSpacing) {
	this.element = svg;
	this.svg = d3.select(svg);
	this.innerSVG = this.svg.append("g");
	this.maxWidth = maxWidth;
	this.innerWidth = maxWidth - this.margins.left - this.margins.right;
	this.barHeight = barHeight;
	this.barSpacing = barSpacing;
	this.maxWidth = maxWidth;

	this.innerSVG.attr("class", "chart-inner").attr("transform", "translate(" + this.margins.left + "," + this.margins.top + ")");

	// this.setSVGWidth();
	this.svg.attr("xmlns", "http://www.w3.org/2000/svg");
	this.svg.attr("preserveAspectRatio", "xMinYMin meet");
};

barChart.prototype.margins = {
	top: 20,
	right: 30,
	bottom: 30,
	left: 140
};

barChart.prototype.scales = {};

barChart.prototype.setSVGWidth = function () {
	this.svg.style("width", this.maxWidth);
};

barChart.prototype.setSVGViewBox = function () {
	this.svg.attr("viewBox", "0 0 " + this.maxWidth + " " + this.calculateTotalHeight());
};

barChart.prototype.setSVGHeight = function () {
	this.svg.style("height", this.calculateTotalHeight());
};

barChart.prototype.calculateTotalHeight = function () {
	return this.calculateInnerHeight() + this.margins.top + this.margins.bottom;
};

barChart.prototype.calculateInnerHeight = function () {
	return this.barHeight * this.data.length;
};

barChart.prototype.buildXScale = function () {
	this.scales.x = d3.scaleLinear().range([0, this.innerWidth]);
};

barChart.prototype.buildYScale = function () {
	this.scales.y = d3.scaleBand().rangeRound([0, this.calculateInnerHeight()]);
};

barChart.prototype.setXScaleDomain = function (data) {
	this.scales.x.domain([0, d3.max(data, this.getValue)]);
};

barChart.prototype.setYScaleDomain = function (data) {
	this.scales.y.domain(data.map(this.getName));
};

barChart.prototype.getBarFillColor = function (data) {
	var cssClass = "bar";

	if (data.value == 0) {
		cssClass = cssClass + " risk-zero";
	} else if (data.value <= 3) {
		cssClass = cssClass + " risk-low";
	} else if (data.value <= 6) {
		cssClass = cssClass + " risk-moderate";
	} else if (data.value <= 10) {
		cssClass = cssClass + " risk-high";
	}

	return cssClass;
};

barChart.prototype.getValue = function (data) {
	return data.value;
};

barChart.prototype.getDisplayValue = function (data) {
	return data.display_value;
};

barChart.prototype.loadData = function (url) {
	var promise = this.getPromise();
	d3.json(url, this.buildChart.bind(this));
	return promise;
};

barChart.prototype.getPromise = function () {
	return new Promise(function (resolve, reject) {
		this.resolve = resolve;
		this.reject = reject;
	}.bind(this));
};

barChart.prototype.createXAxis = function () {
	var tickSize = -(this.calculateTotalHeight() - this.margins.top - this.margins.bottom);
	var height = this.calculateTotalHeight() - this.margins.bottom;
	var axis = d3.axisBottom(this.scales.x).tickSizeInner(tickSize);

	this.svg.append("g").attr("class", "x-axis axis").attr("transform", "translate(" + this.margins.left + "," + height + ")").lower().call(axis);
};

barChart.prototype.createYAxis = function () {
	this.svg.append("g").attr("class", "y-axis axis").attr("transform", "translate(" + this.margins.left + "," + this.margins.top + ")").lower().call(d3.axisLeft(this.scales.y));
};

barChart.prototype.buildChart = function (error, data) {
	this.data = data;

	this.setSVGViewBox();
	// this.setSVGHeight();
	this.buildYScale();

	this.setXScaleDomain(data);
	this.setYScaleDomain(data);

	this.createXAxis();
	this.createYAxis();

	this.buildBars();

	this.resolve(this.element);
};

barChart.prototype.setBarSpacing = function (data, index) {
	return "translate(0," + index * this.barHeight + ")";
};

barChart.prototype.getName = function (data) {
	return data.name;
};

barChart.prototype.buildBars = function () {
	var bars = this.innerSVG.selectAll("g").data(this.data);
	var barsEnter = bars.enter();
	var groups = barsEnter.append("g").attr("transform", this.setBarSpacing.bind(this)).attr("class", this.getBarFillColor);

	this.setBarInner(groups);
	this.setBarText(groups);
};

barChart.prototype.setBarInner = function (groups) {
	groups.append("rect").attr("width", this.getSingleBarWidth.bind(this)).attr("height", this.getSingleBarHeight.bind(this));
};

barChart.prototype.getSingleBarWidth = function (data) {
	return this.scales.x(data.value);
};

barChart.prototype.setBarText = function (groups) {
	groups.append("text").attr("x", this.getTextOffsetX.bind(this)).attr("y", this.getTextOffsetY.bind(this)).attr("dy", ".35em").text(this.getDisplayValue);
};

barChart.prototype.getSingleBarHeight = function (data) {
	return this.barHeight - this.barSpacing;
};

barChart.prototype.getTextOffsetX = function (data) {
	return this.scales.x(data.value) - 10;
};

barChart.prototype.getTextOffsetY = function (data) {
	return this.barHeight / 2;
};

module.exports = barChart;

},{"promise":14}],4:[function(require,module,exports){
"use strict";

var barChart = require("./barChart");

module.exports = function () {
	var svg = document.querySelector(".chart");

	if (!svg) {
		svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		svg.setAttribute("class", "chart");
		svg.id = "chart";
	}

	var chart = new barChart(svg, 700, 50, 10);
	chart.buildXScale();
	return chart.loadData("/api/report.json");
};

},{"./barChart":3}],5:[function(require,module,exports){
"use strict";

var buildChart = require("./build");

module.exports = function () {
	if (document.querySelector(".chart")) {
		buildChart();
	}
};

},{"./build":4}],6:[function(require,module,exports){
"use strict";

var svgInlineStyler = function svgInlineStyler(svg, clone) {
	this.container = document.createElement("div");
	this.container.style["overflow"] = "hidden";
	this.container.style["height"] = "0px";

	if (typeof svg === "string") {
		svg = document.querySelector(svg);
	}

	if (!document.body.contains(svg)) {
		clone = true;
	}

	if (clone) {
		svg = svg.cloneNode(true);
		this.container.appendChild(svg);
	}

	this.svg = svg;
};

svgInlineStyler.prototype.prefix = {
	xmlns: "http://www.w3.org/2000/xmlns/",
	xlink: "http://www.w3.org/1999/xlink",
	svg: "http://www.w3.org/2000/svg"
};

svgInlineStyler.prototype.getSVG = function () {
	return this.svg;
};

svgInlineStyler.prototype.inlineStyles = function () {
	this.defaultSvgStyle = this.getDefaultSvgStyle();
	this.resetSvgAttrs();

	return this.setInlineStyles(this.svg);
};

svgInlineStyler.prototype.getDefaultSvgStyle = function () {
	var emptySvg = window.document.createElementNS(this.prefix.svg, 'svg');
	this.container.appendChild(emptySvg);
	document.body.appendChild(this.container);
	return getComputedStyle(emptySvg);
};

svgInlineStyler.prototype.resetSvgAttrs = function () {
	this.svg.setAttribute("version", "1.1");

	this.svg.removeAttribute("xmlns");
	this.svg.removeAttribute("xlink");

	if (!this.svg.hasAttributeNS(this.prefix.xmlns, "xmlns")) {
		this.svg.setAttributeNS(this.prefix.xmlns, "xmlns", this.prefix.svg);
	}

	if (!this.svg.hasAttributeNS(this.prefix.xmlns, "xmlns:xlink")) {
		this.svg.setAttributeNS(this.prefix.xmlns, "xmlns:xlink", this.prefix.xlink);
	}
};

svgInlineStyler.prototype.setInlineStyles = function (svg) {
	var allElements = this.traverse(svg);
	var index = allElements.length;

	while (index--) {
		this.setInlineStyle(allElements[index]);
	}
	return svg;
};

svgInlineStyler.prototype.traverse = function (obj) {
	var tree = [];
	tree.push(obj);
	visit(obj);

	function visit(node) {
		if (node && node.hasChildNodes()) {
			var child = node.firstChild;
			while (child) {
				if (child.nodeType === 1 && child.nodeName != 'SCRIPT') {
					tree.push(child);
					visit(child);
				}
				child = child.nextSibling;
			}
		}
	}

	return tree;
};

svgInlineStyler.prototype.setInlineStyle = function (element) {
	var clientRect = { width: "auto", height: "auto" };
	var cSSStyleDeclarationComputed = getComputedStyle(element);
	var i, len, key, value;
	var computedStyleStr = "";

	if (element.tagName === "rect") {
		clientRect = element.getBoundingClientRect();
	}

	for (i = 0, len = cSSStyleDeclarationComputed.length; i < len; i++) {
		key = cSSStyleDeclarationComputed[i];
		value = cSSStyleDeclarationComputed.getPropertyValue(key);

		if (value !== this.defaultSvgStyle.getPropertyValue(key)) {
			computedStyleStr += key + ":" + value + ";";
		}
	}

	element.setAttribute('style', computedStyleStr);

	if (element.tagName === "rect") {
		element.style["width"] = element.width.baseVal.valueAsString;;
		element.style["height"] = element.height.baseVal.valueAsString;;
	}
};

module.exports = svgInlineStyler;

},{}],7:[function(require,module,exports){
'use strict';

var svg2png = require("./svg2png");
var Promise = require('promise');

module.exports = function (svg) {
	var postChart = function postChart(data) {

		var request = new XMLHttpRequest();
		request.open('POST', '/api/chart.png', true);
		request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

		var resolvePost = function resolvePost(resolve, reject) {
			request.onreadystatechange = function () {
				if (request.readyState == 4 && request.status == 200) {
					resolve();
				}
			};
		};

		var postPromise = new Promise(resolvePost);
		request.send("chart=" + encodeURIComponent(data.svgURI));
		return postPromise;
	};

	// var pngPromise = new svg2png(svg);
	// return pngPromise.then(postChart);

	var svgURI = new svg2png(svg);
	return postChart(svgURI);
};

},{"./svg2png":8,"promise":14}],8:[function(require,module,exports){
"use strict";

var svgInlineStyler = require("./compute-inline-styles");
var Promise = require('promise');

var svg2png = function svg2png(svg) {
	var styler;

	if (typeof svg === "string") {
		svg = document.querySelector("svg");
	}

	styler = new svgInlineStyler(svg, true);
	this.svg = styler.inlineStyles();

	this.svgData = new XMLSerializer().serializeToString(this.svg);
	this.svgURI = "data:image/svg+xml;base64," + btoa(this.svgData);

	return this.svgURI;

	// this.buildCanvas();
	// this.buildImage();
	// return this.pngPromise();
};

// svg2png.prototype.buildCanvas = function() {
// 	var svgSize = this.svg.getBoundingClientRect();

// 	this.canvas = document.createElement("canvas");

//     this.canvas.width = svgSize.width;
//     this.canvas.height = svgSize.height;	

//     this.context = this.canvas.getContext("2d");
// }

// svg2png.prototype.buildImage = function() {
// 	this.img = document.createElement("img");
// }

// svg2png.prototype.getDataURI = function() {
//     try {
// 	    this.context.drawImage(this.img, 0, 0);
//     }
//     catch (err) {
//         setTimeout(this.context.drawImage(this.img, 0, 0),1);
//     }

// 	this.context.globalCompositeOperation = "destination-over";
// 	this.context.fillStyle = "#ffffff";
// 	this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

// 	window.cvs = this.canvas;

//     return this.canvas.toDataURL("image/png", 1.0);
// };

// svg2png.prototype.pngPromise = function() {
//     return new Promise(this.promiseResolution.bind(this));
// }

// svg2png.prototype.promiseResolution = function(resolve, reject) {
// 	var loadFunc = function() {
// 		window.img = this.img;
//         resolve(this.getDataURI());
//     };

//     var errorFunc = function(){
//         reject();
//     };

//     // this.img.onload = loadFunc.bind(this);
//     this.img.onload = $.proxy(loadFunc, this);
//     this.img.onerror = $.proxy(errorFunc, this);

// 	this.img.setAttribute("src", this.svgURI);
// }

module.exports = svg2png;

},{"./compute-inline-styles":6,"promise":14}],9:[function(require,module,exports){
"use strict";

/* ======================================================================
	App Global
	===================================================================== */

window.App = window.App || {};

/* ==========================================================================
	Slider
	========================================================================= */

var slider = require("./slider");

$(".slider-trigger").on("click", slider);
$(".slider-close").on("click", slider);
$(".slider-open").on("click", slider);

$(".slider-condition").on("change", function (event) {
	slider(event);
});

/* ======================================================================
	Toggle Slider Nav Active State
	===================================================================== */

$(".slider-nav .nav-link").on("click", function (event) {
	event.preventDefault();
	$(this).closest(".slider-nav").find(".active").removeClass("active");
	$(this).closest(".nav-item").addClass("active");
});

$(".nav-item .nav-link").on("click", function (event) {
	$('.feedback-form').removeClass('complete');
	$('.feedback-form').find('.message').empty();
});

/* ======================================================================
	Report Nav // Toggle Download Link
	===================================================================== */

$(".report-toggle-nav .nav-link").on("click", function () {
	var value = $(this).text().toLowerCase();
	$(".download-form").find('[name="pdf[type]"]').val(value);
});

/* ==========================================================================
	Conditional Forms
	========================================================================= */

var conditionalFieldFactory = require("./FieldConditionalFactory");
conditionalFieldFactory.buildFieldConditions();

/* ==========================================================================
	Calculation Fields
	========================================================================= */

var getAge = function getAge(dateString) {
	var today = new Date();
	var birthDate = new Date(dateString);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();

	if (m < 0 || m === 0 && today.getDate() < birthDate.getDate()) {
		age--;
	}
	return age;
};

var check_calculations = function check_calculations($input) {
	var date = $input.val();

	return getAge(date);
};

var find_fields_to_watch = function find_fields_to_watch($points) {
	var toWatch = [];
	$.each($points, function (index, selector) {
		if ($(selector).length) {
			toWatch.push(selector);
		}
	});
	return toWatch;
};

var init_calculation_fields = function init_calculation_fields() {
	$('[data-calculation]').each(function () {
		var $self = $(this);
		var $points = $(this).data("calculation-points");
		var $toWatch = find_fields_to_watch($points);

		$.each($toWatch, function (index, selector) {
			$(selector).on("change", function () {
				var age = check_calculations($(this));
				$self.val(age);
			});
		});
	});
};

init_calculation_fields();

/* ==========================================================================
	Highlighting Missing Fields
	========================================================================= */

var getValueFromName = function getValueFromName(name) {
	var selector = '[name="' + name + '"]';
	var $input = $(selector);

	if ($input.attr("type") === "radio") {
		$input = $(selector + ":checked");
	}

	return $input.val();
};

var handle_row_skip = function handle_row_skip(row) {
	var $row = $(row);
	var $input = $row.find(":input");

	add_remove_row_class($row, $input);
	add_change_event($row, $input);
};

var add_remove_row_class = function add_remove_row_class($row, $input) {
	if (getValueFromName($input.attr("name"))) {
		$row.removeClass("skipped");
	} else {
		$row.addClass("skipped");
	}
};

var add_change_event = function add_change_event($row, $input) {
	$input.on("change", function (event) {
		$row.removeClass("skipped");
	});
};

var attach_events = function attach_events() {
	var $rows = $(".carat-form .form-row:not(.form-footer):not(.message-row)");

	$.each($rows, function (index, row) {
		var $row = $(row);
		var $input = $row.find(":input");

		$input.on("focus", function () {
			for (var i = index - 1; i >= 0; i--) {
				handle_row_skip($rows[i]);
			}
		});
	});
};

attach_events();

/* ======================================================================
	Inline SVG Styles
	===================================================================== */

window.computedToInline = require("computed-style-to-inline-style");

window.inlineStyler = function () {
	var chart = document.querySelector(".chart");
	if (chart) {
		window.computedToInline(chart, true);
	}
};

/* ======================================================================
	Download Form
	===================================================================== */

var saveChart = require("./chart/save");
var buildChart = require("./chart/build");
var buildChartOnLoad = require("./chart/buildOnLoad");
buildChartOnLoad();

$(function () {
	$(".download-form").on("submit", build_chart_for_pdf);
});

var add_loader = function add_loader($form) {
	var $submit = $form.find('input[type="submit"]');
	$submit.addClass("disabled");
	$submit.after('<div class="loader">Loading...</div>');
};

var remove_loader = function remove_loader($form) {
	$form.find('input[type="submit"]').removeClass("disabled");
	$form.find(".loader").remove();
};

var build_chart_for_pdf = function build_chart_for_pdf(event) {
	event.preventDefault();

	var $form = $(this);
	var chart = buildChart();

	add_loader($form);

	chart.then(saveChart).then(function () {
		remove_loader($form);
		$form.off("submit", build_chart_for_pdf);
		$form.trigger("submit");
		$form.on("submit", build_chart_for_pdf);
	});

	return false;
};

/* ======================================================================
	Create New Assessment
	===================================================================== */

$("#new-assessment-btn").on("click", function (event) {
	if (window.confirm("Warning! Starting a new assessment will erase any data entered for this patient. To save the current data you've entered, select 'Cancel' in this message and then select 'Save Data' below.")) {
		return true;
	}
	event.preventDefault();
});

$("#upload-btn").on("click", function (event) {
	if (window.confirm("Warning! Uploading a new assessment will erase any data entered for the current patient. To save the current data you've entered, select 'Cancel' in this message and then select 'Save Data' below.")) {
		return true;
	}
	event.preventDefault();
});

/* ======================================================================
	Dates
	===================================================================== */

var future_date_checker = function future_date_checker(event) {
	var now = new Date();
	var date = new Date($(this).val());

	if (date > now) {
		insert_date_error_message($(this), "Date selected is not allowed. Please enter new date.");
	}
};

var invalid_date_checker = function invalid_date_checker(event) {
	var value = $(this).val();

	if (value.split("/")[0] > 12) {
		insert_date_error_message($(this), "Invalid date format. Please enter new date.");
	}
};

var insert_date_error_message = function insert_date_error_message($input, message) {
	var $error = $('<span class="date-error"></span>');
	$error.text(message);
	$error.insertAfter($input);

	$input.datepicker("clearDates");

	$input.one("changeDate", function (event) {
		$error.remove();
	});
};

var clear_date_error_message = function clear_date_error_message(event) {
	console.log("in event");
	$(event.target).next(".date-error").remove();
};

$('.date-input').on("change", future_date_checker);
$('.date-input').on("changeDate", invalid_date_checker);

/* ======================================================================
	Feedback Form
	===================================================================== */

$(".feedback-form").on("submit", function (event) {
	event.preventDefault();
	var $form = $(this);

	var $ajax = $('<input type="hidden" name="' + App.config.FEEDBACK_KEY + '[ajax]" value="true" />');
	$(this).append($ajax);

	var $submit = $form.find('input[type="submit"]');
	$submit.addClass("disabled");
	$submit.after('<div class="loader">Loading...</div>');

	$.ajax({
		url: $(this).attr('action'),
		type: $(this).attr('method'),
		dataType: 'json',
		data: $(this).serialize(),
		error: function error(response, _error) {
			var $message = $('<p class="error-message">An error occurred. ' + _error + '</p>');
			$form.addClass("error");
			$form.find(".message").html($message);
			$submit.removeClass("disabled");
			$form.find(".loader").remove();
		}
	}).done(function (response) {
		var $message;
		if (response.valid) {
			$message = $('<p class="confirmation-message">Thank you! Your feedback has been sent.</p>');
			$form.addClass("complete");
		} else {
			$message = $('<p class="error-message">An error occurred. ' + response.error_text + '</p>');
			$form.addClass("error");
		}

		$submit.removeClass("disabled");
		$form.find(".loader").remove();
		$form.find(".message").html($message);
	});

	return false;
});

/* ==========================================================================
	Mobile Tester
	========================================================================= */

// var isSmall = function() {
// 	return $(window).width() < 1000;
// };

/* ==========================================================================
	Mobile
	========================================================================= */

// $(".collapsing-nav-trigger").on("click", function(event) {
// 	event.preventDefault();
// 	$(this).closest(".collapsing-nav").toggleClass("open");
// });

},{"./FieldConditionalFactory":2,"./chart/build":4,"./chart/buildOnLoad":5,"./chart/save":7,"./slider":10,"computed-style-to-inline-style":13}],10:[function(require,module,exports){
"use strict";

module.exports = function (event, action) {

	var master = this;
	var runner = {};
	var $cntnr, $collection, $open;

	/* ==========================================================================
 	Get Some Vars
 	========================================================================= */

	// .is() over .hasClass() for SVG support :(
	action = action || false;
	action = $(this).is(".slider-trigger") ? "toggleClass" : action;
	action = !action && $(this).is(".slider-close") ? "removeClass" : action;
	action = !action && $(this).is(".slider-open") ? "addClass" : action;

	$cntnr = $(this).data("slider-group") ? $($(this).data("slider-group")) : $(this).closest(".slider-group");
	$collection = $cntnr.data("slider-collection") ? $($cntnr.data("slider-collection")) : false;

	/* ==========================================================================
 	Transition Detection
 	========================================================================= */

	function whichTransitionEvent() {
		var t,
		    el = document.createElement("fakeelement");

		var transitions = {
			"transition": "transitionend",
			"OTransition": "oTransitionEnd",
			"MozTransition": "transitionend",
			"WebkitTransition": "webkitTransitionEnd"
		};

		for (t in transitions) {
			if (el.style[t] !== undefined) {
				return transitions[t];
			}
		}
	}

	var transitionEvent = whichTransitionEvent();

	/* ==========================================================================
 	Runner
 	========================================================================= */

	runner.run = function ($cntnr, action) {

		var self = this;

		/* ==========================================================================
  	Var Setup
  	========================================================================= */

		this.action = action;
		this.$cntnr = $cntnr;
		this.type = this.$cntnr.data("slide");
		this.$target = this.$cntnr.find(".slider-target");

		/* ==========================================================================
  	Make a Clone for Size Comparisons
  	========================================================================= */

		this.makeClone = function () {
			self.$clone = self.$cntnr.clone();

			var $checked = self.$target.find(":checked");
			$checked.prop("checked", false);

			self.$clone.css({
				display: 'block',
				position: 'fixed',
				visibility: 'hidden'
			});

			self.$clone.appendTo(self.$cntnr.parent());
			self.$cloneTarget = self.$clone.find(".slider-target");
			$checked.prop("checked", true);
		};

		/* ==========================================================================
  	Animation Types
  	========================================================================= */

		this.vertical = function () {
			// First explicitly set our current height
			self.$target.height(self.$cloneTarget.outerHeight());

			// Then toggle the class, and set our new height
			self.$clone[self.action]("open");
			var height = self.$cloneTarget.outerHeight();
			self.$target.height(height);
		};

		this.horizontal = function () {
			// First explicitly set our current width
			self.$target.width(self.$cloneTarget.outerWidth());

			// Then toggle the class, and set our new width
			self.$clone[self.action]("open");
			var width = self.$cloneTarget.outerWidth();
			self.$target.width(width);
		};

		/* ==========================================================================
  	Callable Funcs
  	========================================================================= */

		this.toggle = function () {
			self.$cntnr.toggleClass("open");
		};

		this.open = function () {
			self.$cntnr.remove("open");
		};

		this.close = function () {
			self.$cntnr.remove("open");
		};

		/* ==========================================================================
  	Utility
  	========================================================================= */

		this.cleanup = function () {
			self.$clone.remove();
		};

		/* ==========================================================================
  	Do the thing.
  	========================================================================= */

		if (!self.$cntnr.hasClass("open") && self.action === "addClass" || self.$cntnr.hasClass("open") && self.action === "removeClass" || self.action === "toggleClass") {
			if (event) {
				event.preventDefault();
			}

			self.$target.one(transitionEvent, function (event) {
				$(this).removeClass("animating").removeAttr("style");
			});

			this.makeClone();
			this.$target.addClass("animating");
			this[this.type].call();
			this.$cntnr.trigger(self.action, [this.$target]);

			this.$cntnr[self.action]("open");
			this.cleanup();
		}
	};

	/* ==========================================================================
 	Do the Things
 	========================================================================= */

	if ($collection) {
		$open = $collection.find(".open");
		$open.length && !$open.is($cntnr) && runner.run($open, "removeClass");
	}

	$cntnr.each(function () {
		runner.run($(this), action);
	});
};

},{}],11:[function(require,module,exports){
"use strict";

// rawAsap provides everything we need except exception management.
var rawAsap = require("./raw");
// RawTasks are recycled to reduce GC churn.
var freeTasks = [];
// We queue errors to ensure they are thrown in right order (FIFO).
// Array-as-queue is good enough here, since we are just dealing with exceptions.
var pendingErrors = [];
var requestErrorThrow = rawAsap.makeRequestCallFromTimer(throwFirstError);

function throwFirstError() {
    if (pendingErrors.length) {
        throw pendingErrors.shift();
    }
}

/**
 * Calls a task as soon as possible after returning, in its own event, with priority
 * over other events like animation, reflow, and repaint. An error thrown from an
 * event will not interrupt, nor even substantially slow down the processing of
 * other events, but will be rather postponed to a lower priority event.
 * @param {{call}} task A callable object, typically a function that takes no
 * arguments.
 */
module.exports = asap;
function asap(task) {
    var rawTask;
    if (freeTasks.length) {
        rawTask = freeTasks.pop();
    } else {
        rawTask = new RawTask();
    }
    rawTask.task = task;
    rawAsap(rawTask);
}

// We wrap tasks with recyclable task objects.  A task object implements
// `call`, just like a function.
function RawTask() {
    this.task = null;
}

// The sole purpose of wrapping the task is to catch the exception and recycle
// the task object after its single use.
RawTask.prototype.call = function () {
    try {
        this.task.call();
    } catch (error) {
        if (asap.onerror) {
            // This hook exists purely for testing purposes.
            // Its name will be periodically randomized to break any code that
            // depends on its existence.
            asap.onerror(error);
        } else {
            // In a web browser, exceptions are not fatal. However, to avoid
            // slowing down the queue of pending tasks, we rethrow the error in a
            // lower priority turn.
            pendingErrors.push(error);
            requestErrorThrow();
        }
    } finally {
        this.task = null;
        freeTasks[freeTasks.length] = this;
    }
};

},{"./raw":12}],12:[function(require,module,exports){
(function (global){
"use strict";

// Use the fastest means possible to execute a task in its own turn, with
// priority over other events including IO, animation, reflow, and redraw
// events in browsers.
//
// An exception thrown by a task will permanently interrupt the processing of
// subsequent tasks. The higher level `asap` function ensures that if an
// exception is thrown by a task, that the task queue will continue flushing as
// soon as possible, but if you use `rawAsap` directly, you are responsible to
// either ensure that no exceptions are thrown from your task, or to manually
// call `rawAsap.requestFlush` if an exception is thrown.
module.exports = rawAsap;
function rawAsap(task) {
    if (!queue.length) {
        requestFlush();
        flushing = true;
    }
    // Equivalent to push, but avoids a function call.
    queue[queue.length] = task;
}

var queue = [];
// Once a flush has been requested, no further calls to `requestFlush` are
// necessary until the next `flush` completes.
var flushing = false;
// `requestFlush` is an implementation-specific method that attempts to kick
// off a `flush` event as quickly as possible. `flush` will attempt to exhaust
// the event queue before yielding to the browser's own event loop.
var requestFlush;
// The position of the next task to execute in the task queue. This is
// preserved between calls to `flush` so that it can be resumed if
// a task throws an exception.
var index = 0;
// If a task schedules additional tasks recursively, the task queue can grow
// unbounded. To prevent memory exhaustion, the task queue will periodically
// truncate already-completed tasks.
var capacity = 1024;

// The flush function processes all tasks that have been scheduled with
// `rawAsap` unless and until one of those tasks throws an exception.
// If a task throws an exception, `flush` ensures that its state will remain
// consistent and will resume where it left off when called again.
// However, `flush` does not make any arrangements to be called again if an
// exception is thrown.
function flush() {
    while (index < queue.length) {
        var currentIndex = index;
        // Advance the index before calling the task. This ensures that we will
        // begin flushing on the next task the task throws an error.
        index = index + 1;
        queue[currentIndex].call();
        // Prevent leaking memory for long chains of recursive calls to `asap`.
        // If we call `asap` within tasks scheduled by `asap`, the queue will
        // grow, but to avoid an O(n) walk for every task we execute, we don't
        // shift tasks off the queue after they have been executed.
        // Instead, we periodically shift 1024 tasks off the queue.
        if (index > capacity) {
            // Manually shift all values starting at the index back to the
            // beginning of the queue.
            for (var scan = 0, newLength = queue.length - index; scan < newLength; scan++) {
                queue[scan] = queue[scan + index];
            }
            queue.length -= index;
            index = 0;
        }
    }
    queue.length = 0;
    index = 0;
    flushing = false;
}

// `requestFlush` is implemented using a strategy based on data collected from
// every available SauceLabs Selenium web driver worker at time of writing.
// https://docs.google.com/spreadsheets/d/1mG-5UYGup5qxGdEMWkhP6BWCz053NUb2E1QoUTU16uA/edit#gid=783724593

// Safari 6 and 6.1 for desktop, iPad, and iPhone are the only browsers that
// have WebKitMutationObserver but not un-prefixed MutationObserver.
// Must use `global` or `self` instead of `window` to work in both frames and web
// workers. `global` is a provision of Browserify, Mr, Mrs, or Mop.

/* globals self */
var scope = typeof global !== "undefined" ? global : self;
var BrowserMutationObserver = scope.MutationObserver || scope.WebKitMutationObserver;

// MutationObservers are desirable because they have high priority and work
// reliably everywhere they are implemented.
// They are implemented in all modern browsers.
//
// - Android 4-4.3
// - Chrome 26-34
// - Firefox 14-29
// - Internet Explorer 11
// - iPad Safari 6-7.1
// - iPhone Safari 7-7.1
// - Safari 6-7
if (typeof BrowserMutationObserver === "function") {
    requestFlush = makeRequestCallFromMutationObserver(flush);

// MessageChannels are desirable because they give direct access to the HTML
// task queue, are implemented in Internet Explorer 10, Safari 5.0-1, and Opera
// 11-12, and in web workers in many engines.
// Although message channels yield to any queued rendering and IO tasks, they
// would be better than imposing the 4ms delay of timers.
// However, they do not work reliably in Internet Explorer or Safari.

// Internet Explorer 10 is the only browser that has setImmediate but does
// not have MutationObservers.
// Although setImmediate yields to the browser's renderer, it would be
// preferrable to falling back to setTimeout since it does not have
// the minimum 4ms penalty.
// Unfortunately there appears to be a bug in Internet Explorer 10 Mobile (and
// Desktop to a lesser extent) that renders both setImmediate and
// MessageChannel useless for the purposes of ASAP.
// https://github.com/kriskowal/q/issues/396

// Timers are implemented universally.
// We fall back to timers in workers in most engines, and in foreground
// contexts in the following browsers.
// However, note that even this simple case requires nuances to operate in a
// broad spectrum of browsers.
//
// - Firefox 3-13
// - Internet Explorer 6-9
// - iPad Safari 4.3
// - Lynx 2.8.7
} else {
    requestFlush = makeRequestCallFromTimer(flush);
}

// `requestFlush` requests that the high priority event queue be flushed as
// soon as possible.
// This is useful to prevent an error thrown in a task from stalling the event
// queue if the exception handled by Node.js’s
// `process.on("uncaughtException")` or by a domain.
rawAsap.requestFlush = requestFlush;

// To request a high priority event, we induce a mutation observer by toggling
// the text of a text node between "1" and "-1".
function makeRequestCallFromMutationObserver(callback) {
    var toggle = 1;
    var observer = new BrowserMutationObserver(callback);
    var node = document.createTextNode("");
    observer.observe(node, {characterData: true});
    return function requestCall() {
        toggle = -toggle;
        node.data = toggle;
    };
}

// The message channel technique was discovered by Malte Ubl and was the
// original foundation for this library.
// http://www.nonblocking.io/2011/06/windownexttick.html

// Safari 6.0.5 (at least) intermittently fails to create message ports on a
// page's first load. Thankfully, this version of Safari supports
// MutationObservers, so we don't need to fall back in that case.

// function makeRequestCallFromMessageChannel(callback) {
//     var channel = new MessageChannel();
//     channel.port1.onmessage = callback;
//     return function requestCall() {
//         channel.port2.postMessage(0);
//     };
// }

// For reasons explained above, we are also unable to use `setImmediate`
// under any circumstances.
// Even if we were, there is another bug in Internet Explorer 10.
// It is not sufficient to assign `setImmediate` to `requestFlush` because
// `setImmediate` must be called *by name* and therefore must be wrapped in a
// closure.
// Never forget.

// function makeRequestCallFromSetImmediate(callback) {
//     return function requestCall() {
//         setImmediate(callback);
//     };
// }

// Safari 6.0 has a problem where timers will get lost while the user is
// scrolling. This problem does not impact ASAP because Safari 6.0 supports
// mutation observers, so that implementation is used instead.
// However, if we ever elect to use timers in Safari, the prevalent work-around
// is to add a scroll event listener that calls for a flush.

// `setTimeout` does not call the passed callback if the delay is less than
// approximately 7 in web workers in Firefox 8 through 18, and sometimes not
// even then.

function makeRequestCallFromTimer(callback) {
    return function requestCall() {
        // We dispatch a timeout with a specified delay of 0 for engines that
        // can reliably accommodate that request. This will usually be snapped
        // to a 4 milisecond delay, but once we're flushing, there's no delay
        // between events.
        var timeoutHandle = setTimeout(handleTimer, 0);
        // However, since this timer gets frequently dropped in Firefox
        // workers, we enlist an interval handle that will try to fire
        // an event 20 times per second until it succeeds.
        var intervalHandle = setInterval(handleTimer, 50);

        function handleTimer() {
            // Whichever timer succeeds will cancel both timers and
            // execute the callback.
            clearTimeout(timeoutHandle);
            clearInterval(intervalHandle);
            callback();
        }
    };
}

// This is for `asap.js` only.
// Its name will be periodically randomized to break any code that depends on
// its existence.
rawAsap.makeRequestCallFromTimer = makeRequestCallFromTimer;

// ASAP was originally a nextTick shim included in Q. This was factored out
// into this ASAP package. It was later adapted to RSVP which made further
// amendments. These decisions, particularly to marginalize MessageChannel and
// to capture the MutationObserver implementation in a closure, were integrated
// back into ASAP proper.
// https://github.com/tildeio/rsvp.js/blob/cddf7232546a9cf858524b75cde6f9edf72620a7/lib/rsvp/asap.js

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{}],13:[function(require,module,exports){
(function(root, factory) {
  if (typeof define === "function" && define.amd) {
    define([], factory); // AMD.
  } else if (typeof module === 'object' && module.exports) {
    module.exports = factory(); // CommonJS.
  } else {
    root.computedStyleToInlineStyle = factory(); // Browser.
  }
}(this, function() {
  return function computedStyleToInlineStyle(element, recursive) {
    if (!element) {
      throw new Error("No element specified.");
    }

    if (!(element instanceof Element)) {
      throw new Error("Specified element is not an instance of Element.");
    }

    if (recursive) {
      Array.prototype.forEach.call(element.children, function(child) {
        computedStyleToInlineStyle(child, recursive);
      });
    }

    var computedStyle = getComputedStyle(element, null);
    for (var i = 0; i < computedStyle.length; i++) {
      var property = computedStyle.item(i);
      var value = computedStyle.getPropertyValue(property);
      element.style[property] = value;
    }
  };
}));

},{}],14:[function(require,module,exports){
'use strict';

module.exports = require('./lib')

},{"./lib":19}],15:[function(require,module,exports){
'use strict';

var asap = require('asap/raw');

function noop() {}

// States:
//
// 0 - pending
// 1 - fulfilled with _value
// 2 - rejected with _value
// 3 - adopted the state of another promise, _value
//
// once the state is no longer pending (0) it is immutable

// All `_` prefixed properties will be reduced to `_{random number}`
// at build time to obfuscate them and discourage their use.
// We don't use symbols or Object.defineProperty to fully hide them
// because the performance isn't good enough.


// to avoid using try/catch inside critical functions, we
// extract them to here.
var LAST_ERROR = null;
var IS_ERROR = {};
function getThen(obj) {
  try {
    return obj.then;
  } catch (ex) {
    LAST_ERROR = ex;
    return IS_ERROR;
  }
}

function tryCallOne(fn, a) {
  try {
    return fn(a);
  } catch (ex) {
    LAST_ERROR = ex;
    return IS_ERROR;
  }
}
function tryCallTwo(fn, a, b) {
  try {
    fn(a, b);
  } catch (ex) {
    LAST_ERROR = ex;
    return IS_ERROR;
  }
}

module.exports = Promise;

function Promise(fn) {
  if (typeof this !== 'object') {
    throw new TypeError('Promises must be constructed via new');
  }
  if (typeof fn !== 'function') {
    throw new TypeError('Promise constructor\'s argument is not a function');
  }
  this._40 = 0;
  this._65 = 0;
  this._55 = null;
  this._72 = null;
  if (fn === noop) return;
  doResolve(fn, this);
}
Promise._37 = null;
Promise._87 = null;
Promise._61 = noop;

Promise.prototype.then = function(onFulfilled, onRejected) {
  if (this.constructor !== Promise) {
    return safeThen(this, onFulfilled, onRejected);
  }
  var res = new Promise(noop);
  handle(this, new Handler(onFulfilled, onRejected, res));
  return res;
};

function safeThen(self, onFulfilled, onRejected) {
  return new self.constructor(function (resolve, reject) {
    var res = new Promise(noop);
    res.then(resolve, reject);
    handle(self, new Handler(onFulfilled, onRejected, res));
  });
}
function handle(self, deferred) {
  while (self._65 === 3) {
    self = self._55;
  }
  if (Promise._37) {
    Promise._37(self);
  }
  if (self._65 === 0) {
    if (self._40 === 0) {
      self._40 = 1;
      self._72 = deferred;
      return;
    }
    if (self._40 === 1) {
      self._40 = 2;
      self._72 = [self._72, deferred];
      return;
    }
    self._72.push(deferred);
    return;
  }
  handleResolved(self, deferred);
}

function handleResolved(self, deferred) {
  asap(function() {
    var cb = self._65 === 1 ? deferred.onFulfilled : deferred.onRejected;
    if (cb === null) {
      if (self._65 === 1) {
        resolve(deferred.promise, self._55);
      } else {
        reject(deferred.promise, self._55);
      }
      return;
    }
    var ret = tryCallOne(cb, self._55);
    if (ret === IS_ERROR) {
      reject(deferred.promise, LAST_ERROR);
    } else {
      resolve(deferred.promise, ret);
    }
  });
}
function resolve(self, newValue) {
  // Promise Resolution Procedure: https://github.com/promises-aplus/promises-spec#the-promise-resolution-procedure
  if (newValue === self) {
    return reject(
      self,
      new TypeError('A promise cannot be resolved with itself.')
    );
  }
  if (
    newValue &&
    (typeof newValue === 'object' || typeof newValue === 'function')
  ) {
    var then = getThen(newValue);
    if (then === IS_ERROR) {
      return reject(self, LAST_ERROR);
    }
    if (
      then === self.then &&
      newValue instanceof Promise
    ) {
      self._65 = 3;
      self._55 = newValue;
      finale(self);
      return;
    } else if (typeof then === 'function') {
      doResolve(then.bind(newValue), self);
      return;
    }
  }
  self._65 = 1;
  self._55 = newValue;
  finale(self);
}

function reject(self, newValue) {
  self._65 = 2;
  self._55 = newValue;
  if (Promise._87) {
    Promise._87(self, newValue);
  }
  finale(self);
}
function finale(self) {
  if (self._40 === 1) {
    handle(self, self._72);
    self._72 = null;
  }
  if (self._40 === 2) {
    for (var i = 0; i < self._72.length; i++) {
      handle(self, self._72[i]);
    }
    self._72 = null;
  }
}

function Handler(onFulfilled, onRejected, promise){
  this.onFulfilled = typeof onFulfilled === 'function' ? onFulfilled : null;
  this.onRejected = typeof onRejected === 'function' ? onRejected : null;
  this.promise = promise;
}

/**
 * Take a potentially misbehaving resolver function and make sure
 * onFulfilled and onRejected are only called once.
 *
 * Makes no guarantees about asynchrony.
 */
function doResolve(fn, promise) {
  var done = false;
  var res = tryCallTwo(fn, function (value) {
    if (done) return;
    done = true;
    resolve(promise, value);
  }, function (reason) {
    if (done) return;
    done = true;
    reject(promise, reason);
  });
  if (!done && res === IS_ERROR) {
    done = true;
    reject(promise, LAST_ERROR);
  }
}

},{"asap/raw":12}],16:[function(require,module,exports){
'use strict';

var Promise = require('./core.js');

module.exports = Promise;
Promise.prototype.done = function (onFulfilled, onRejected) {
  var self = arguments.length ? this.then.apply(this, arguments) : this;
  self.then(null, function (err) {
    setTimeout(function () {
      throw err;
    }, 0);
  });
};

},{"./core.js":15}],17:[function(require,module,exports){
'use strict';

//This file contains the ES6 extensions to the core Promises/A+ API

var Promise = require('./core.js');

module.exports = Promise;

/* Static Functions */

var TRUE = valuePromise(true);
var FALSE = valuePromise(false);
var NULL = valuePromise(null);
var UNDEFINED = valuePromise(undefined);
var ZERO = valuePromise(0);
var EMPTYSTRING = valuePromise('');

function valuePromise(value) {
  var p = new Promise(Promise._61);
  p._65 = 1;
  p._55 = value;
  return p;
}
Promise.resolve = function (value) {
  if (value instanceof Promise) return value;

  if (value === null) return NULL;
  if (value === undefined) return UNDEFINED;
  if (value === true) return TRUE;
  if (value === false) return FALSE;
  if (value === 0) return ZERO;
  if (value === '') return EMPTYSTRING;

  if (typeof value === 'object' || typeof value === 'function') {
    try {
      var then = value.then;
      if (typeof then === 'function') {
        return new Promise(then.bind(value));
      }
    } catch (ex) {
      return new Promise(function (resolve, reject) {
        reject(ex);
      });
    }
  }
  return valuePromise(value);
};

Promise.all = function (arr) {
  var args = Array.prototype.slice.call(arr);

  return new Promise(function (resolve, reject) {
    if (args.length === 0) return resolve([]);
    var remaining = args.length;
    function res(i, val) {
      if (val && (typeof val === 'object' || typeof val === 'function')) {
        if (val instanceof Promise && val.then === Promise.prototype.then) {
          while (val._65 === 3) {
            val = val._55;
          }
          if (val._65 === 1) return res(i, val._55);
          if (val._65 === 2) reject(val._55);
          val.then(function (val) {
            res(i, val);
          }, reject);
          return;
        } else {
          var then = val.then;
          if (typeof then === 'function') {
            var p = new Promise(then.bind(val));
            p.then(function (val) {
              res(i, val);
            }, reject);
            return;
          }
        }
      }
      args[i] = val;
      if (--remaining === 0) {
        resolve(args);
      }
    }
    for (var i = 0; i < args.length; i++) {
      res(i, args[i]);
    }
  });
};

Promise.reject = function (value) {
  return new Promise(function (resolve, reject) {
    reject(value);
  });
};

Promise.race = function (values) {
  return new Promise(function (resolve, reject) {
    values.forEach(function(value){
      Promise.resolve(value).then(resolve, reject);
    });
  });
};

/* Prototype Methods */

Promise.prototype['catch'] = function (onRejected) {
  return this.then(null, onRejected);
};

},{"./core.js":15}],18:[function(require,module,exports){
'use strict';

var Promise = require('./core.js');

module.exports = Promise;
Promise.prototype['finally'] = function (f) {
  return this.then(function (value) {
    return Promise.resolve(f()).then(function () {
      return value;
    });
  }, function (err) {
    return Promise.resolve(f()).then(function () {
      throw err;
    });
  });
};

},{"./core.js":15}],19:[function(require,module,exports){
'use strict';

module.exports = require('./core.js');
require('./done.js');
require('./finally.js');
require('./es6-extensions.js');
require('./node-extensions.js');
require('./synchronous.js');

},{"./core.js":15,"./done.js":16,"./es6-extensions.js":17,"./finally.js":18,"./node-extensions.js":20,"./synchronous.js":21}],20:[function(require,module,exports){
'use strict';

// This file contains then/promise specific extensions that are only useful
// for node.js interop

var Promise = require('./core.js');
var asap = require('asap');

module.exports = Promise;

/* Static Functions */

Promise.denodeify = function (fn, argumentCount) {
  if (
    typeof argumentCount === 'number' && argumentCount !== Infinity
  ) {
    return denodeifyWithCount(fn, argumentCount);
  } else {
    return denodeifyWithoutCount(fn);
  }
};

var callbackFn = (
  'function (err, res) {' +
  'if (err) { rj(err); } else { rs(res); }' +
  '}'
);
function denodeifyWithCount(fn, argumentCount) {
  var args = [];
  for (var i = 0; i < argumentCount; i++) {
    args.push('a' + i);
  }
  var body = [
    'return function (' + args.join(',') + ') {',
    'var self = this;',
    'return new Promise(function (rs, rj) {',
    'var res = fn.call(',
    ['self'].concat(args).concat([callbackFn]).join(','),
    ');',
    'if (res &&',
    '(typeof res === "object" || typeof res === "function") &&',
    'typeof res.then === "function"',
    ') {rs(res);}',
    '});',
    '};'
  ].join('');
  return Function(['Promise', 'fn'], body)(Promise, fn);
}
function denodeifyWithoutCount(fn) {
  var fnLength = Math.max(fn.length - 1, 3);
  var args = [];
  for (var i = 0; i < fnLength; i++) {
    args.push('a' + i);
  }
  var body = [
    'return function (' + args.join(',') + ') {',
    'var self = this;',
    'var args;',
    'var argLength = arguments.length;',
    'if (arguments.length > ' + fnLength + ') {',
    'args = new Array(arguments.length + 1);',
    'for (var i = 0; i < arguments.length; i++) {',
    'args[i] = arguments[i];',
    '}',
    '}',
    'return new Promise(function (rs, rj) {',
    'var cb = ' + callbackFn + ';',
    'var res;',
    'switch (argLength) {',
    args.concat(['extra']).map(function (_, index) {
      return (
        'case ' + (index) + ':' +
        'res = fn.call(' + ['self'].concat(args.slice(0, index)).concat('cb').join(',') + ');' +
        'break;'
      );
    }).join(''),
    'default:',
    'args[argLength] = cb;',
    'res = fn.apply(self, args);',
    '}',
    
    'if (res &&',
    '(typeof res === "object" || typeof res === "function") &&',
    'typeof res.then === "function"',
    ') {rs(res);}',
    '});',
    '};'
  ].join('');

  return Function(
    ['Promise', 'fn'],
    body
  )(Promise, fn);
}

Promise.nodeify = function (fn) {
  return function () {
    var args = Array.prototype.slice.call(arguments);
    var callback =
      typeof args[args.length - 1] === 'function' ? args.pop() : null;
    var ctx = this;
    try {
      return fn.apply(this, arguments).nodeify(callback, ctx);
    } catch (ex) {
      if (callback === null || typeof callback == 'undefined') {
        return new Promise(function (resolve, reject) {
          reject(ex);
        });
      } else {
        asap(function () {
          callback.call(ctx, ex);
        })
      }
    }
  }
};

Promise.prototype.nodeify = function (callback, ctx) {
  if (typeof callback != 'function') return this;

  this.then(function (value) {
    asap(function () {
      callback.call(ctx, null, value);
    });
  }, function (err) {
    asap(function () {
      callback.call(ctx, err);
    });
  });
};

},{"./core.js":15,"asap":11}],21:[function(require,module,exports){
'use strict';

var Promise = require('./core.js');

module.exports = Promise;
Promise.enableSynchronous = function () {
  Promise.prototype.isPending = function() {
    return this.getState() == 0;
  };

  Promise.prototype.isFulfilled = function() {
    return this.getState() == 1;
  };

  Promise.prototype.isRejected = function() {
    return this.getState() == 2;
  };

  Promise.prototype.getValue = function () {
    if (this._65 === 3) {
      return this._55.getValue();
    }

    if (!this.isFulfilled()) {
      throw new Error('Cannot get a value of an unfulfilled promise.');
    }

    return this._55;
  };

  Promise.prototype.getReason = function () {
    if (this._65 === 3) {
      return this._55.getReason();
    }

    if (!this.isRejected()) {
      throw new Error('Cannot get a rejection reason of a non-rejected promise.');
    }

    return this._55;
  };

  Promise.prototype.getState = function () {
    if (this._65 === 3) {
      return this._55.getState();
    }
    if (this._65 === -1 || this._65 === -2) {
      return 0;
    }

    return this._65;
  };
};

Promise.disableSynchronous = function() {
  Promise.prototype.isPending = undefined;
  Promise.prototype.isFulfilled = undefined;
  Promise.prototype.isRejected = undefined;
  Promise.prototype.getValue = undefined;
  Promise.prototype.getReason = undefined;
  Promise.prototype.getState = undefined;
};

},{"./core.js":15}]},{},[9])

